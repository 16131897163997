import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Wrapper } from '@farewill/ui'
import { getQueryParameters } from 'lib/url/getQueryParameters'

import DefaultLayout from 'layouts/DefaultLayout'
import BlogArticleContent from 'views/general/templates/BlogArticle/BlogArticleContent'

import { getDynamicContentForArticle, getSlugs } from '../helpers'
import Loader from '../../components/Loader'
import PreviewModeSwitch from '../../components/PreviewModeSwitch'

let requestCache

const Blog = ({ data }) => {
  const [context, setContext] = useState()
  const [article, setArticle] = useState()
  const [isPreviewMode, setIsPreviewMode] = useState(true)
  const [hasLiveMode, setHasLiveMode] = useState(false)
  const { slug } = getQueryParameters()

  useEffect(() => {
    setContext(getSlugs(data, slug))
  }, [])

  useEffect(() => {
    if (context) {
      if (!requestCache) {
        getDynamicContentForArticle('blog', context).then((res) => {
          requestCache = res
          setArticle(isPreviewMode ? res.preview : res.live)
          setHasLiveMode(Object.entries(res.live).length > 0)
        })
      } else {
        setArticle(isPreviewMode ? requestCache.preview : requestCache.live)
      }
    }
  }, [isPreviewMode, context])

  return (
    <>
      <Wrapper
        style={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <DefaultLayout
          title={article?.metaTitle || article?.title || 'Article Preview'}
          description={
            (article?.metaDescription &&
              article?.metaDescription.metaDescription) ||
            (article?.description && article?.description.description)
          }
          meta={[
            {
              name: 'robots',
              content: 'noindex',
            },
          ]}
        >
          {article ? (
            <>
              <PreviewModeSwitch
                isPreviewMode={isPreviewMode}
                hasLiveMode={hasLiveMode}
                onChange={() =>
                  setIsPreviewMode((prevState) =>
                    hasLiveMode ? !prevState : true
                  )
                }
              />
              <BlogArticleContent data={{ article }} />
            </>
          ) : (
            <Wrapper centered>
              <Loader />
            </Wrapper>
          )}
        </DefaultLayout>
      </Wrapper>
    </>
  )
}

Blog.propTypes = {
  data: PropTypes.shape({
    allContentfulArticle: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            author: PropTypes.shape({
              slug: PropTypes.string,
            }),
            reviewedAt: PropTypes.string,
            slug: PropTypes.string,
            type: PropTypes.string,
            updatedAt: PropTypes.string,
          }),
        })
      ),
    }),
    allContentfulGuide: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            articles: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string,
              })
            ),
            slug: PropTypes.string,
            updatedAt: PropTypes.string,
          }),
        })
      ),
    }),
  }).isRequired,
}

export const query = graphql`
  query {
    allContentfulArticle(filter: { slug: { ne: null } }) {
      edges {
        node {
          type
          slug
          updatedAt
          reviewedAt
          author {
            slug
          }
        }
      }
    }

    allContentfulGuide(filter: { slug: { ne: null } }) {
      edges {
        node {
          slug
          updatedAt
          articles {
            slug
          }
        }
      }
    }
  }
`

export default Blog
